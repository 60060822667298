$(document).ready(function() {
        // Manually set the first button as active on initial load for desktop
        $('.carousel-controls-custom-desktop .active-control').removeClass('btn-primary').addClass('btn-outline-primary');
        $('.carousel-controls-custom-desktop .active-control').eq(0).removeClass('btn-outline-primary').addClass('btn-primary').addClass('active');
        
        // Manually set the first button as active on initial load for mobile
        $('.carousel-controls-custom-mobile .active-control').removeClass('btn-primary').addClass('btn-outline-primary');
        $('.carousel-controls-custom-mobile .active-control').eq(0).removeClass('btn-outline-primary').addClass('btn-primary').addClass('active');
        
        // Listen for the slide event on the carousel
        $('#featuresCarousel').on('slid.bs.carousel', function () {
            // Get the index of the currently active item
            var activeIndex = $('#featuresCarousel .carousel-item.active').index();
            
            // Remove 'active' styling from all control buttons for desktop
            $('.carousel-controls-custom-desktop .active-control').removeClass('btn-primary').addClass('btn-outline-primary');
            
            // Add 'active' styling to the current control button for desktop
            $('.carousel-controls-custom-desktop .active-control').eq(activeIndex).removeClass('btn-outline-primary').addClass('btn-primary').addClass('active');
            
            // Remove 'active' styling from all control buttons for mobile
            $('.carousel-controls-custom-mobile .btn').removeClass('btn-primary').addClass('btn-outline-primary');
            
            // Add 'active' styling to the current control button for mobile
            $('.carousel-controls-custom-mobile .btn').eq(activeIndex).removeClass('btn-outline-primary').addClass('btn-primary').addClass('active');
        });
    });