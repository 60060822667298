import AOS from 'aos';
import 'aos/dist/aos.css';

document.addEventListener('DOMContentLoaded', function() {
    AOS.init({
        duration: 800, // values from 0 to 3000, with step 50ms
        easing: 'ease',
        disable: function() {
            var maxWidth = 800;
            return window.innerWidth < maxWidth;
        }
    });
});